import * as React from "react";
import Layout from "../../components/Layout";
import { graphql, PageProps } from "gatsby";
import { LocalisedPageContext } from "web-common/src/types/PageTypes";
import {
  LocalizedContextInterface,
  SanitySearchPageInterface,
  SanityHomePageInterface,
  SanitySearchPageInterfaceV2
} from "web-common/src/types/SanityTypes";
import SEO from "../../components/Seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "../../components/Link";
import "./styles.scss";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

type NotFoundProps = {
  sanityFourOhFour: {
    heading: string;
    seo: {
      metaTitle: string;
      metaDescription: string;
    };
    searchButtonText: string;
  };
} & LocalizedContextInterface;

type NotFoundContext = LocalisedPageContext;

export const query = graphql`
  query NotFound($_id: String, $language: String, $market: String) {
    sanityFourOhFour(_id: { eq: $_id }) {
      heading
      seo {
        metaTitle
        metaDescription
      }
      searchButtonText
    }
    ...LocalizedContext
  }
`;

const NotFound = (props: PageProps<NotFoundProps, NotFoundContext>) => {
  const homePage = props.data.sanityHomePage as SanityHomePageInterface;
  const search = props.data.sanitySearch as SanitySearchPageInterface;
  const searchPageV2 = props.data.sanitySearchItems as SanitySearchPageInterfaceV2;
  const fourOhFour = props.data.sanityFourOhFour;
  const seoTitle = fourOhFour.seo?.metaTitle;
  const seoDescription = fourOhFour.seo?.metaDescription;
  const { searchV2 } = useSiteMetadata();

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={"404"}
      pageType={"404"}
      contentType={"404"}
    >
      {seoDescription?.length > 0 && seoTitle?.length > 0 ? (
        <SEO title={seoTitle} description={seoDescription} />
      ) : null}
      <div className="page_not-found">
        <Container fluid>
          <h1>{props.data.sanityFourOhFour.heading}</h1>
          <Row className="justify-content-around">
            <Col lg={4} className="buttons">
              <Link _id={homePage._id} to={homePage.slug.current} className="btn-primary">
                {homePage.name}
              </Link>
            </Col>
            <Col lg={4} className="buttons">
              {!searchV2 ? (
                <Link _id={search._id} to={search.slug.current} className="btn-primary">
                  {props.data.sanityFourOhFour.searchButtonText}
                </Link>
              ) : (
                <Link _id={searchPageV2?._id} to={searchPageV2?.slug?.current} className="btn-primary">
                  {props.data.sanityFourOhFour.searchButtonText}
                </Link>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default NotFound;
